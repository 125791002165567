import { User } from './user';

export interface TimestampedModel {
  createdAt: string;
  modifiedAt: string;
}

interface ModifiedByModel {
  createdBy?: User;
  modifiedBy?: User;
}

export interface CommonInfoModel extends TimestampedModel, ModifiedByModel {}

export interface BaseModelChoice {
  id: number;
}

export interface UserModelChoice extends BaseModelChoice {
  fullName: string;
}

export interface ContractorProjectModelChoice extends BaseModelChoice {
  name: string;
}

export interface ConstructionProjectModelChoice extends BaseModelChoice {
  name: string;
}

export interface CustomerModelChoice extends BaseModelChoice {
  companyName: string | null;
  recipient: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface ExportModelField {
  name: string;
  verboseName: string;
}

export enum AutocompleteModel {
  CONSTRUCTION_PROJECT = 'general_contractors.ConstructionProject',
  CONTRACTOR_PROJECT = 'contractors.ContractorProject',
  USER = 'users.User',
  CUSTOMER = 'customers.Customer',
}

export type IDDict<T> = { [id: number]: T };
